import { PlatformOptionsType } from '../types';

export const PUBLIC_ROUTE = {
  login: '/login',
  auth0Callback: '/auth0/callback',
  notFound: '/404',
  tokenExpired: '/expired-merchant-tọken',
};

export const PRIVATE_ROUTE = {
  dashboard: '/dashboard',
  pendingReview: '/pending-review',
  accountUnavailable: '/account-unavailable',
  merchantRegister: '/merchant-register',
  campaigns: '/campaigns',
  campaignsOfProfile: '/campaigns-of-merchant/:kol_profile_id',
  campaignsCreate: '/campaigns/create',
  campaignsDetails: '/campaigns/:id/details',
  campaignsListOfKols: '/campaigns/:id/list-of-kols',
  campaignsChat: '/campaigns/:id/list-of-kols/chat/:contractID',
  campaignsDeliverables: '/campaigns/:id/list-of-kols/deliverables/:contractID',
  campaignsReport: '/campaigns/:id/campaign-report',
  companyProfile: '/company-profile',
  userProfile: '/user-profile',
  wallet: '/wallet',
  contentMetrics: '/campaigns/:id/:kol_profile_id/content-metrics',
  publishCampaignTerms: '/publish-campaign-terms',
  teams: '/teams',
  selectMerchant: '/select-merchant',
  clientRegister: '/client-register',
};

export const ROUTES = {
  ...PUBLIC_ROUTE,
  ...PRIVATE_ROUTE,
};

export const DEFAULT_AVATAR = '/static/images/campaigns/user.png';
export const DEFAULT_AVATAR_MOBILE = '/static/images/campaigns/profile.png';

export const MESSAGE_APPROVAL_REQUEST = {
  approved: 'APPROVED',
  rejected: 'REJECTED',
  pending: 'PENDING',
};

export const FORMAT_DATE_DEFAULT = 'dd/MM/yyyy';
export const FORMAT_DATE_WALLET_DEFAULT = 'dd/MM/yyyy HH:mm';

export const VIEW_MODE = {
  list: 0,
  grid: 1,
};

export const MODE = {
  view: '0',
  edit: '1',
};

export const amountArray = [500, 2500, 5000];

export const platformOptions: PlatformOptionsType[] = [
  {
    value: 'ig_',
    name: 'Instagram',
  },
  {
    value: 'tt_',
    name: 'TikTok',
  },
  {
    value: 'xhs_',
    name: 'Xiaohongshu',
  },
  {
    value: 'l8_',
    name: 'Lemon8',
  },
  {
    value: 'youtube_',
    name: 'YouTube',
  },
  {
    value: 'fb_',
    name: 'Facebook',
  },
  {
    value: 'others',
    name: 'Others',
  },
];

export const platformCodes: string[] = ['ig_', 'tt_', 'xhs_', 'l8_', 'youtube_', 'fb_'];
