import { useContext } from 'react';
import styled from 'styled-components';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { PRIVATE_ROUTE, PUBLIC_ROUTE } from '../../utils/constants';
import useGuard from '../../hooks/useGuard';
import useExcludeRoute from '../../hooks/useExcludeRoute';
import ClientProfileContext from '../../containers/ClientProfileProvider';

const Container = styled.div<any>`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: transparent linear-gradient(254deg, #51adf0 0%, #666bfa 49%, #b64dc8 100%) 0% 0% no-repeat padding-box;
`;

const MerchantRoute = (props) => {
  const location = useLocation();
  const { path } = props;
  const isExclude = useExcludeRoute(path);
  const { isLogged, user, isAccountActive, isProfileCreated, isProfileApproved, isLoading, isProfileActive } = useGuard();
  const { currentProfile, loading: profileLoading, isLoadingProfile } = useContext(ClientProfileContext);

  if (isLoading || profileLoading || isLoadingProfile) {
    return <Container />;
  }
  const isCampaignRoute = location.pathname.includes('/campaigns/');
  if (isLogged && props.path !== PUBLIC_ROUTE.tokenExpired) {
    if (currentProfile === null || currentProfile === undefined) {
      if (props.path !== PRIVATE_ROUTE.selectMerchant && props.path !== PRIVATE_ROUTE.clientRegister) {
        return <Redirect to={PRIVATE_ROUTE.selectMerchant} />;
      }
    } else if (currentProfile.isApproved === false) {
      if (props.path !== PRIVATE_ROUTE.pendingReview) {
        return <Redirect to={PRIVATE_ROUTE.pendingReview} />;
      }
    } else if (currentProfile.isActive === false) {
      if (props.path !== PRIVATE_ROUTE.accountUnavailable) {
        return <Redirect to={PRIVATE_ROUTE.accountUnavailable} />;
      }
    } else if (isExclude && !isCampaignRoute) {
      return <Redirect to={PRIVATE_ROUTE.campaigns} />;
    }
  }

  if (props.private) {
    return <PrivateRoute {...props} user={user} isLogged={isLogged} isAccountActive={isProfileCreated} />;
  }
  return <PublicRoute {...props} user={user} isLogged={isLogged} isAccountActive={isProfileCreated} />;
};

const PrivateRoute = ({ component: Component, isLogged, ...rest }) => {
  return <Route {...rest} render={(props) => (isLogged ? <Component {...props} /> : <Redirect to={PUBLIC_ROUTE.login} />)} />;
};

const PublicRoute = ({ component: Component, restricted, isLogged, ...rest }) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) => (isLogged && restricted ? <Redirect to={PRIVATE_ROUTE.campaigns} /> : <Component {...props} />)}
    />
  );
};

export default MerchantRoute;
